<template>
    <v-expand-transition>
      <router-view />
    </v-expand-transition>
</template>
<script>
import refreshData from '@/mixins/mixins'

export default {
  name: "EntityDetail",
  components: {},
  mixins:[refreshData],
  data() {
    return {
      apiUrl: "/entity/get?id=" + this.$route.params.entity_id,
      mutation: "entities/setEntityData"
    };
  },
  created() {
    this.refreshData();
  },
  // destroyed() {
  //   this.$store.dispatch('base/SET_SUB_MENU', [])
  //   this.$store.dispatch("base/SET_HEADER_ACTIONS", {});
  // },
  beforeRouteLeave (to, from, next) {
      this.$store.dispatch('base/SET_SUB_MENU', []);
      this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
      next();
  },
  methods: {},
  computed: {
    entity: function() {
      return this.$store.state.entities.entity;
    },
  },
  watch: {
    $route(to, from) {
      this.apiUrl = "/entity/get?id=" + to.params.entity_id,
      this.refreshData();
    },
    entity(newVal) {
      // Ajout des subActions
      if(newVal.actions && newVal.actions.get) {
        newVal.actions.get.forEach(element => element.item ? element.item = JSON.parse(JSON.stringify(this.entity)) : '');
        this.$store.dispatch("base/SET_HEADER_ACTIONS", newVal.actions.get);
      }
    }
  }
};
</script>
<style lang="scss">
</style>