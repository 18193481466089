<template>
    <v-expand-transition>
        <router-view />
    </v-expand-transition>
</template>
<script>
import refreshData from '@/mixins/mixins'

export default {
    name: "MissionDetail",
    components: {},
    mixins:[refreshData],
    data() {
        return {
            apiUrl: "/mission/get?id=" + this.$route.params.mission_id,
            mutation: "missions/setMissionData"
        };
    },
    created() {
        this.refreshData();
    },
    // destroyed() {
    //     this.$store.dispatch('base/SET_SUB_MENU', [])
    //     this.$store.dispatch("base/SET_HEADER_ACTIONS", {});
    // },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('base/SET_SUB_MENU', []);
        this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
        next();
    },
    methods: {},
    computed: {
        mission: function() {
            return this.$store.state.missions.mission;
        },
    },
    watch: {
        $route(to, from) {
            this.apiUrl = "/mission/get?id=" + to.params.mission_id,
            this.refreshData();
        },
        mission(newVal) {
            // Ajout des subActions
            this.$store.dispatch("base/SET_HEADER_ACTIONS", {});
            if(newVal.actions && newVal.actions.get) {
                newVal.actions.get.forEach(element => element.item ? element.item = JSON.parse(JSON.stringify(this.mission)) : '');
                this.$store.dispatch("base/SET_HEADER_ACTIONS", newVal.actions.get);
            }
        }
    }
};
</script>

<style lang="scss">
</style>