import { mapActions } from "vuex";

export default {
    methods: {
        ...mapActions("alerts", ["setAlert"]),
        async getForm(url, arrayIds = false, action = false, apiUrl = null, mutation = null, displayForm = true, forceCustomRefresh = false) {
            let payload = {}
            if(arrayIds){
                payload.ids = arrayIds
            }
            this.formUrl = url
            await this.$store
              .dispatch("forms/GET_FORM_DATA", {
                url: this.formUrl,
                payload: payload
              })
              .then((e) => {
                this.form = this.$store.state.forms.forms[e.reference];
                if(displayForm) {
                  setTimeout(() => {
                    this.displayForm(this.form.params.title, action, e.reference, apiUrl, mutation, forceCustomRefresh);
                  }, 100);  
                }
              });
        },
        async displayForm(title, action, reference, apiUrl = null, mutation = null, forceCustomRefresh = false) {
          await this.$refs.componentForm.open(this.$t(title)).then((response) => {
              if(response && response.data && response.data.success) {
                this.displayDatatableAlert(response);
                if (response.nextStep) {
                  this.$store.state.forms.forms[reference].schema.steps[Object.keys(this.$store.state.forms.forms[reference].schema.steps)[response.nextStep - 2]].is_validate = true;
                  this.$store.state.forms.forms[reference].schema.current_step = response.nextStep;
                  this.$store.state.forms.forms[reference].model.id = response.data.data.id;
                  this.displayForm(title, action, reference, apiUrl, mutation);
                } else if(action && action.redirect) {
                  if(action.redirect.params)
                    Object.keys(action.redirect.params).map((e) => action.redirect.params[e] = response.data.data[e]);
                      
                  this.$router.push(action.redirect); 
                } else if(action && action.event) {
                  if(action.event == "launchSegmentation"){
                    this[action.event](response.data.data);
                  } else if(action.event == "redirectCall"){
                    this[action.event](response.data.data);
                  } else if(action.returnFullObject){
                    this[action.event](response.data.data);
                  } else {
                    this[action.event](response.data.data.id);
                  }                 
                } else {
                  if(apiUrl != null || mutation != null || this.apiUrl != null || this.mutations != null) {
                    this.refreshData(null, apiUrl, mutation);
                  } else {
                    this.customRefresh(response)
                  }

                  if(forceCustomRefresh) // Permet de quand même jouer le customRefresh si on le veux
                    this.customRefresh(response)
                }
              } else {
                if(response && typeof this.customRefresh === "function")
                  this.customRefresh(response); // Je l'ai mis pour reload les campagnes out d'une opé quand on quitte le stepper
              }
          })
            
        },
        displayDatatableAlert(response){
          if(response && response.data && response.data.data && typeof response.data.data.success === 'boolean' && response.data.data.success === false) {
            this.$store.dispatch('ADD_ERROR', response.data.data);
          } else {
            this.$store.dispatch('ADD_SNACK', {
              message : this.$t('successActionConfirmed'),
            }).then((e) => {
              this.$store.dispatch("REMOVE_SNACK")
            });
          }
        },
         refreshData(payload = null, apiUrl = null, mutation = null) {
          let json = {};
          if (this.tableData && this.tableData.columns) {
            json = {
              fields: this.tableData.columns.selected
            }
          }
             if(payload) {
               json.filters = payload
               this.filters = payload
             } else {
               json.filters = this.filters
             }
            if(apiUrl || this.apiUrl) {
                let url = apiUrl ? apiUrl : this.apiUrl;
                if(typeof url === 'string' || url instanceof String) {
                this.$store
                    .dispatch("SET_DATA", {
                    url: url,
                    mutation: mutation ? mutation : this.mutation,
                    data: json,
                    }).then((e) => {
                        if(this.tableData){
                            this.tableData = this.$store.state[this.storeModule][this.storeVariable]
                            this.$store.dispatch("SET_LOADER", false)
                        }
                    }, (error) => {
                        this.apiError = error
                    })
                }
            }
         },
    }
} 